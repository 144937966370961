import React from 'react'
import AssignmentTemplateCreatePage from '../../../components/pages/StarTutoring/AssignmentTemplateCreatePage'
import StarTutoringLayout from '../../../components/pages/StarTutoring/StarTutoringLayout'

const AssignmentTemplateCreate = (props: any) => {
  return <StarTutoringLayout path={props.path}>
    <AssignmentTemplateCreatePage />
  </StarTutoringLayout>
}

export default AssignmentTemplateCreate
